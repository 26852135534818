<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <ApeMenu :menus="menus" />
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'Performance',
  components: {
    PageHeaderLayout,
  },
  data() {
    return {
      menus: [
        {
          icon: 'mubiao',
          display_name: '人工成本',
          url: '/analysis_basic/labor_cost',
          permission_name: 'kol-analysis-setting-list'
        },
        {
          icon: 'discovery-index',
          display_name: '返点费用',
          url: '/analysis_basic/rebate',
          permission_name: 'rebate_list'
        },
        {
          icon: 'doc-success',
          display_name: 'MCN推广',
          url: '/analysis_basic/mcn_promotion',
          permission_name: 'mcn_promote_list'
        },
        {
          icon: 'doc-success',
          display_name: '直播投放金',
          url: '/analysis_basic/live_funding',
          permission_name: 'live_put_list'
        },
        {
          icon: 'discovery-index',
          display_name: '商务支持团队费用',
          url: '/analysis_basic/business_support_team',
          permission_name: 'basic_expenses_by_bst_list'
        },
        {
          icon: 'zhuanzu',
          display_name: '红人模型-网红分成',
          url: '/analysis_basic/divide_into',
          permission_name: 'basic_expenses_by_aob_list'
        },
        {
          icon: 'fencheng',
          display_name: '办公租金装修',
          url: '/analysis_basic/renovation_rent',
          permission_name: 'basic_expenses_by_orr_list'
        },
        {
          icon: 'liebiao',
          display_name: '管理服务费',
          url: '/analysis_basic/manage_fee',
          permission_name: 'basic_expenses_by_msf_list'
        }, {
          icon: 'liebiao',
          display_name: '报销明细',
          url: '/analysis_basic/expense_index',
          permission_name: 'basic_expenses_by_ea_list'
        },
      ],
    }
  },
}
</script>

<style scoped></style>
